<!-- 这个原为是页面 改成组件引入了 -->
<template>
    <div style="text-align: left;margin-left: auto;margin-right: auto;padding-bottom: 60px;">
        <el-form :model="goodForm" :rules="goodFormRules" ref="goodForm" label-width="80px" label-position="right">
            <div class="my-form-horizontal m-t-40">
                        <!-- {{goodForm.globalClassifyId}} ,checkStrictly: true-- -->
                <el-form-item label="产品类型">
                    <el-select v-model="categoryId" placeholder="请选择" @change="init">
                        <el-option v-for="item in [{value: 0,label: '家具类'},{value: 1,label: '材料类'}]" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="类型分类" prop="classify">
                    <el-select v-model="goodForm.classify">
                        <el-option v-for="item in classifyData" :key="item.id" v-if="categoryId==item.classifyCategory" :label="item.classifyName" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="平台归类" prop="globalClassifyId">
                    <el-cascader v-model="goodForm.globalClassifyId" :options="allGlobalClassifyList" :show-all-levels="false" :props="{ expandTrigger: 'hover' }"></el-cascader>
                </el-form-item>
            </div>
            <el-card shadow="never" style="margin-bottom: 25px;">
                <div slot="header" class="my-header">
                    <div class="title">基础信息</div>
                </div>
                <div>
                    <el-form-item label="产品名称" prop="spuName">
                        <el-input v-model="goodForm.spuName" autocomplete="off" placeholder="最多允许输入40个汉字" maxlength="40" show-word-limit></el-input>
                    </el-form-item>
                    <el-form-item label="产品主图" prop="spuImgs">
                        <div>
                            <upload-video style="display: inline-block;position: relative;top:10px;" @uploadVideoBack="uploadVideoBack" size="mini" list-type="picture-card">
                                <video v-if="goodForm.spuVideo" @click="$oucy.openNewPage($oucy.ossUrl+ goodForm.spuVideo)" :src="$oucy.ossUrl+ goodForm.spuVideo" style="width:100%;height: 100%;background: #f1f1f1;"></video>
                                <i v-else class="el-icon-film avatar-uploader-icon" title="添加视频"></i>
                                <i v-if="goodForm.spuVideo" class="el-icon-delete" title="删除" style="color:#fff;position:absolute;top: 4px;right: 4px; cursor: default;text-shadow: 1px 1px 2px #000;font-size: 16px;" @click.stop="goodForm.spuVideo=null"></i>
                            </upload-video>
                            <!-- 多选时显示的图片 -->
                            <span v-for="(file,i) in goodForm.spuImgs" :key="i" style="display:inline-block;position: relative;margin-right: 10px;position: relative;cursor: move;" v-dragging="{ item: file, list: goodForm.spuImgs, group: 'file' }">
                                <img class="el-upload-list__item-thumbnail" :src="$oucy.ossUrl+file.url" alt="" style="width:80px;display: inline-block;border-radius: 10px;">
                                <i class="el-icon-delete" title="删除" style="color:#fff;position:absolute;top: 10px;right: 10px; cursor: default;text-shadow: 1px 1px 2px #000;" @click="handleImagePreviewRemove(file)"></i>
                            </span>
                            <!-- 上传剪图组件 -->
                            <upload-image style="display: inline-block;position: relative;top:10px" @uploadImageBack="uploadImageBack2" :multiple="true" :limit="20" size="mini" list-type="picture-card">
                                <div>
                                    <i class="el-icon-plus" title="添加图片"></i>
                                </div>
                            </upload-image>
                        </div>
                    </el-form-item>
                    <!-- <div class="my-form-horizontal"> -->
<!--                         <el-form-item label="平台归类" v-if="0">
                            <el-radio-group v-model="categoryId" @change="init" style="width: 340px">
                                <el-radio :label="0" border>家具类</el-radio>
                                <el-radio :label="1" border>材料类</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="分类" prop="classify" v-if="0">
                            <el-select v-model="goodForm.classify" style="width: 340px">
                                <el-option v-for="item in classifyData" :key="item.id" v-if="categoryId==item.classifyCategory" :label="item.classifyName" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="平台分类" prop="globalClassifyId">
                            <el-cascader style="width: 340px" v-model="goodForm.globalClassifyId" :options="allGlobalClassifyList" :show-all-levels="false" :props="{ expandTrigger: 'hover' }"></el-cascader>
                        </el-form-item> 
                        <el-form-item label="产品排序">
                            <el-input-number style="width: 340px" v-model="goodForm.spuRankingWeight" :min="0" :max="10000" label="描述文字"></el-input-number>
                        </el-form-item>
                    -->
                    <!-- </div> -->
                </div>
                <div class="my-form-horizontal">
                    <el-form-item label="产品排序">
                        <el-input-number style="width: 340px" v-model="goodForm.spuRankingWeight" :min="0" :max="10000" label="描述文字"></el-input-number>
                    </el-form-item>
                    <el-form-item label="产品品牌" prop="spuBrand">
                        <el-select v-model="goodForm.spuBrand" placeholder="请选择" style="width: 340px">
                            <template v-for="item in userAffectBrands">
                                <el-option :label="item.brandName" :value="item.id">
                                    <div class="selected-brand">
                                        <div class="selected-brand-left">
                                            <img :src="$oucy.ossUrl+item.brandIcon" style="width: 25px;height: 25px;border-radius: 6px;margin: 0 5px 0 0;">
                                            <span>{{item.brandName}}</span>
                                        </div>
                                        <div style="color: gray;font-size: 13px;padding: 0 5px;">{{item.brandDesc}}</div>
                                    </div>
                                </el-option>
                            </template>
                        </el-select>
                    </el-form-item>
    
                    <template v-if="globalAttrs && globalAttrs.length>0">
                        <!-- <div class="my-form-horizontal"> -->
                            <template v-for="(item,index) in globalAttrs">
                                <template v-if="item.furnitureGlobalAttrOption && item.furnitureGlobalAttrOption.length>0">
                                    <el-form-item :label="item.attrName" :required="item.attrShouldSelect">
                                        <el-select v-model="goodForm.globalAttrs[index]" placeholder="请选择" style="width: 340px" @change="item.validate=false">
                                            <el-option v-for="option in item.furnitureGlobalAttrOption" :key="option.id" :label="option.optionValue" :value="option.id">
                                            </el-option>
                                        </el-select>
                                        <div class="el-form-item__error" v-if="item.validate">请选择{{item.attrName}}</div>
                                    </el-form-item>
                                </template>
                            </template>
                        <!-- </div> -->
                    </template>
                <!-- </div> -->
                <!-- <div class="my-form-horizontal"> -->
                    <el-form-item label="零售价格" prop="skuSinglePrise">
                        <el-input-number v-model="goodForm.skuSinglePrise" :precision="0" :cision="2" :step="100" :min="0" style="width: 340px" />
                    <div class="f14 cf">(建议会员价x2倍)</div>
                    </el-form-item>
                    <el-form-item label="会员价格" prop="skuMemberSinglePrise">
                        <el-input-number v-model="goodForm.skuMemberSinglePrise" :precision="0" :cision="2" :step="100" :min="0" style="width: 340px" />
                    </el-form-item>
                    <el-form-item label="产品库存" prop="skuSingleRepertory">
                        <el-input-number v-model="goodForm.skuSingleRepertory" :precision="0" :min="0" :step="100" style="width: 340px" />
                    </el-form-item>
                </div>
                        <div class="f14">注：<span class="cf">如果有规格的情况下，以上零售价格、会员价格均无效，将以规格价格为主</span></div>
            </el-card>
            <!-- 产品参数 -->
            <el-card shadow="never" style="margin-bottom: 25px;">
                <div slot="header" class="my-header">
                    <div class="title">产品参数</div>
                    <div>
                        <el-button icon="el-icon-menu" type="primary" size="mini" @click="openAddSpecDialog">选择模版</el-button>
                        <el-button @click="addSpecOption" icon="el-icon-circle-plus" type="success" size="mini">添加参数</el-button>
                    </div>
                </div>
                <div v-if="goodForm.specTableData && goodForm.specTableData.length>0">
                    <el-table :data="goodForm.specTableData" border style="width: 100%; margin-top: 20px">
                        <el-table-column type="index" width="50"></el-table-column>
                        <el-table-column label="参数名">
                            <template slot-scope="scope">
                                <template v-if="scope.row.affect">
                                    {{scope.row.key}}
                                </template>
                                <template v-else>
                                    <el-input size="mini" placeholder="请输入参数名" v-model.trim="goodForm.specTableData[scope.$index].key" />
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column label="参数值">
                            <template slot-scope="scope">
                                <template v-if="scope.row.affect">
                                    {{scope.row.value}}
                                </template>
                                <template v-else>
                                    <el-input size="mini" placeholder="请输入参数值" v-model.trim="goodForm.specTableData[scope.$index].value" />
                                </template>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="205" align="center">
                            <template slot-scope="scope">
                                <template v-if="scope.row.affect">
                                    <el-button @click="editSpecTableData(scope.$index)" icon="el-icon-edit" size="mini">编辑</el-button>
                                </template>
                                <template v-else>
                                    <el-button @click="saveSpecTableData(scope.$index)" icon="el-icon-finished" size="mini">保存</el-button>
                                </template>
                                <el-button @click="deleteSpecTableData(scope.$index)" icon="el-icon-delete" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
            <!-- 产品规格 -->
            <el-card shadow="never" style="margin-bottom: 25px;">
                <div slot="header" class="my-header">
                    <div class="title">产品规格</div>
                    <div>
                        <el-button icon="el-icon-menu" type="primary" size="mini" @click="openAddAttrDialog">选择模版</el-button>
                        <el-button @click="addAttr" icon="el-icon-circle-plus" type="success" size="mini">添加规格</el-button>
                    </div>
                </div>
                <!-- {{attrTableData}} -->
                <div v-if="goodForm && attrTableData && attrTableData.length">
                    <div v-for="(v,i) of attrTableData" :key="i">
                        <div class="df-jc-s-b">
                            <div>
                                <el-radio @click.native.prevent="handleSpuAttrUserImgChange(v[0].attrName)" v-model="goodForm.spuAttrUserImg" :label="v[0].attrName">使用规格图片</el-radio>
                            </div>
                            <div>
                                <el-button icon="el-icon-edit" type="primary" plain size="mini" @click="editAttr(v[0],i)">修改</el-button>
                                <el-button icon="el-icon-delete" type="primary" plain size="mini" @click="deleteAttrOption(v[0],i)">删除</el-button>
                            </div>
                        </div>
                        <!-- :span-method="(param)=>cellMerge(param,item.length)" -->
                        <el-table :data="v" :span-method="(param) => goodFormSpanMethod(param)"  border style="width: 100%; margin-top: 10px">
<!--                             <el-table-column label="使用规格图片" width="130" align="center">
                                <template slot-scope="scope">
                                    <el-radio @click.native.prevent="handleSpuAttrUserImgChange(scope.row.attrName)" v-model="goodForm.spuAttrUserImg" :label="scope.row.attrName">&nbsp;</el-radio>
                                </template>
                            </el-table-column> -->
                            <el-table-column label="规格名称" align="center" width="180">
                                <template slot-scope="scope">
                                    {{scope.row.attrName}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="attrOptionName" align="center" label="规格值">
                                <template slot-scope="scope">
                                        <span>
                                            {{scope.row.attrOptionName}}
                                        </span>
                                </template>
                            </el-table-column>
                            <el-table-column label="规格图" align="center" width="130" v-if="
                                goodForm.spuAttrUserImg
                                &&
                                v[0].attrOptionName
                                &&
                                goodForm.spuAttrUserImg === v[0].attrName">
                                <template slot-scope="scope" v-if="
                                goodForm.spuAttrUserImg
                                &&
                                scope.row.attrOptionName
                                &&
                                goodForm.spuAttrUserImg === scope.row.attrName
                                ">
                                    <div class="spuAttrImgCell">
                                        <upload-image @uploadImageBack="uploadImageBack" :avata="scope.row.attrOptionImg" @click.native="setUploadImageObj(scope.row)" class="avatar-uploader" size="mini">
                                            <img v-if="scope.row.attrOptionImg && scope.row.attrOptionImg!='null'" :src="$oucy.ossUrl+scope.row.attrOptionImg" class="avatar">
                                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                        </upload-image>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="attrOptionName" label="操作" width="180" align="center">
                                <template slot-scope="scope">
                                        <div v-if="scope.row.attrOptionName">
                                            <div>                                                
                                                <el-button size="mini" type="" icon="el-icon-top" @click="move('up',scope.row,scope.$index)">上移</el-button>
                                                <el-button size="mini" type="" icon="el-icon-edit-outline" @click="editAttrOption(scope.row,scope.$index)">修改</el-button>
                                            </div>
                                            <div class="m-t-10">
                                                <el-button size="mini" type="" icon="el-icon-bottom" @click="move('dn',scope.row,scope.$index)">下移</el-button>
                                                <el-button size="mini" type="" icon="el-icon-delete" @click="deleteAttrOptionName(scope.row,scope.$index, i)">删除</el-button>
                                            </div>
                                        </div>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div class="text-center m-t-20 p-b-20">
                            <el-button @click="addAttrOption(v[0].attrName)" size="mini" type="primary" plain style="width: 200px;">添加规格值</el-button>
                            
                        </div>
                    </div>
                </div>

                <div v-if="0">
                    <el-table :data="goodForm.attrTableData" :span-method="goodFormSpanMethod" border style="width: 100%; margin-top: 20px">
                        <el-table-column label="使用规格图片" width="130" align="center">
                            <template slot-scope="scope">
                                <el-radio @click.native.prevent="handleSpuAttrUserImgChange(scope.row.attrName)" v-model="goodForm.spuAttrUserImg" :label="scope.row.attrName">&nbsp;</el-radio>
                            </template>
                        </el-table-column>
                        <el-table-column label="规格名称" width="180">
                            <template slot-scope="scope">
                                {{scope.row.attrName}}
                                <div>
                                    <el-button type="text" @click="editAttr(scope.row,scope.$index)">修改</el-button>
                                    <el-button type="text" @click="deleteAttrOption(scope.row,scope.$index)">删除</el-button>
                                    <el-button @click="addAttrOption(scope.row.attrName)" type="text">添加规格值</el-button>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="attrOptionName" label="规格值">
                            <template slot-scope="scope">
                                <div class="df-jc-s-b df-ai-c">
                                    <span>
                                        {{scope.row.attrOptionName}}
                                    </span>
                                    <span v-if="scope.row.attrOptionName">
                                        <el-button icon="el-icon-bottom" @click="move('dn',scope.row,scope.$index)"></el-button>
                                        <el-button icon="el-icon-top" @click="move('up',scope.row,scope.$index)"></el-button>
                                        <el-button type="text" @click="editAttrOption(scope.row,scope.$index)">修改</el-button>
                                        <el-button type="text" @click="deleteAttrOptionName(scope.row,scope.$index,i)">删除</el-button>
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="规格图" align="center" width="130">
                            <template slot-scope="scope" v-if="
                            goodForm.spuAttrUserImg
                            &&
                            scope.row.attrOptionName
                            &&
                            goodForm.spuAttrUserImg === scope.row.attrName
                            ">
                                <div class="spuAttrImgCell">
                                    <upload-image @uploadImageBack="uploadImageBack" :avata="scope.row.attrOptionImg" @click.native="setUploadImageObj(scope.row)" class="avatar-uploader" size="mini">
                                        <img v-if="scope.row.attrOptionImg" :src="$oucy.ossUrl+scope.row.attrOptionImg" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </upload-image>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
            <el-card shadow="never" style="margin-bottom: 25px;">
                <div slot="header" class="my-header">
                    <div>设置库存/价格</div>
                    <div>
                        <el-button @click="batchSetPriseRepertoryDialogVisible=true" type="text" :disabled="!(multipleSelection && multipleSelection.length)">批量设置库存/价格</el-button>
                    </div>
                </div>
                <div v-if="goodForm.priseTableData && goodForm.priseTableData.length>0">
                    <el-table :data="goodForm.priseTableData" border style="width: 100%; margin-top: 20px" @selection-change="handleSelectionChange">
                        <el-table-column type="selection" width="50"></el-table-column>
                        <el-table-column type="index" label="序号" width="50"></el-table-column>
                        <template v-for="(item, index) of goodForm.priseTableData[0].attr" >
                            <el-table-column :label="item.key" :key="item.key">
                                <template slot-scope="scope">
                                    {{scope.row.attr[index].value}}
                                </template>
                            </el-table-column>
                        </template>
                        <el-table-column label="库存(:件)" width="130" align="center">
                            <template slot-scope="scope">
                                <el-input-number v-model="scope.row.repertory" size="mini" style="width: 100px;" controls-position="right" :min="0" />
                            </template>
                        </el-table-column>
                        <el-table-column label="价格(:元)" width="130" align="center">
                            <template slot-scope="scope">
                                <el-input-number v-model="scope.row.prise" size="mini" :precision="0" style="width: 100px;" controls-position="right" :min="0" />
                            </template>
                        </el-table-column>
                        <el-table-column label="会员价格(:元)" width="130" align="center">
                            <template slot-scope="scope">
                                <el-input-number v-model="scope.row.priseMember" :precision="0" size="mini" style="width: 100px;" controls-position="right" :min="0" />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
            <el-card shadow="never" style="margin-bottom: 25px;">
                <div slot="header" class="my-header">
                    <div>产品标签</div>
                </div>
                <div>
                    <span class="btn m-r-10" :class="{'select':goodForm.spuIsNew}" @click="goodForm.spuIsNew=!goodForm.spuIsNew">
                        新品
                        <img src="@/assets/images/stockCar/corner.png" class="corner">
                    </span>
                    <span class="btn m-r-10" :class="{'select':goodForm.spuIsSpecial}" @click="goodForm.spuIsSpecial=!goodForm.spuIsSpecial">
                        特价
                        <img src="@/assets/images/stockCar/corner.png" class="corner">
                    </span>
                    <span class="btn m-r-10" :class="{'select':goodForm.spuIsRecommend}" @click="goodForm.spuIsRecommend=!goodForm.spuIsRecommend">
                        主推
                        <img src="@/assets/images/stockCar/corner.png" class="corner">
                    </span>

     <!--                <el-checkbox v-model="goodForm.spuIsNew">新品</el-checkbox>
                    <el-checkbox v-model="goodForm.spuIsSpecial">特价</el-checkbox>
                    <el-checkbox v-model="goodForm.spuIsRecommend">主推</el-checkbox> -->
                    <!-- <el-checkbox-group v-model="goodForm.points">
                        <el-checkbox label="spuIsNew" name="type" border>新品</el-checkbox>
                        <el-checkbox label="" name="type" border></el-checkbox>
                    </el-checkbox-group> -->
                </div>
            </el-card>
            <el-card shadow="never" style="margin-bottom: 25px;">
                <div slot="header" class="my-header">
                    <div>产品简介</div>
                </div>
                <div class="editor-container" style="position:relative;">
                    <quill-editor class='editor' v-show="false" ref="myQuillEditor" v-model="goodForm.describer" :options="quillEditorOption" />
                    <Mywangeditor ref="Mywangeditor"></Mywangeditor>
                </div>
            </el-card>
                <div id="scrollerFooter"></div>
        </el-form>
        <div style="text-align: center;" class="bottomBtn" :class="bottomBtnClass">
            <el-button @click="SaveDrafts" type="" v-if="!spuId">保存草稿</el-button>
            <!-- <el-button @click="RestoreDrafts" type="primary">恢复草稿</el-button> -->
            <el-button @click="publishGood" type="primary">立即发布</el-button>
            <el-button type="default" @click="$oucy.back()">取消发布</el-button>
        </div>
        <!--图片预览-->
        <el-dialog :visible.sync="dialogImagePreviewVisible" width="600px"  z-index="10002">
            <img width="100%" :src="$oucy.ossUrl+dialogImagePreviewUrl" alt="">
        </el-dialog>
        <input style="display: none" :id="hiddenFileInputId" type="file" name="file" multiple accept="image/jpg, image/jpeg, image/png, image/gif" @change="realEditorUploadImg" />
        <el-dialog title="选择规格模板" :visible.sync="addAttrModuleDialogVisible" width="600px" z-index="10002">
            <add-attr @addAttrBack="addAttrBack" ref="addAttr"></add-attr>
        </el-dialog>
        <el-dialog title="选择参数模板" :visible.sync="addSpecModuleDialogVisible" width="600px" z-index="10002">
            <add-spec @addSpecBack="addSpecBack" ref="addSpec"></add-spec>
        </el-dialog>
        <el-dialog title="批量设置库存/价格" :visible.sync="batchSetPriseRepertoryDialogVisible" width="600px" z-index="10002">
            <el-form label-width="80px">
                <!-- <el-form-item label="批量设置库存"> -->
                <!-- </el-form-item> -->
                <el-form-item label="库存">
                    <el-checkbox v-model="isRepertory">批量设置库存</el-checkbox>
                    <el-input-number v-model="repertory" :disabled="!isRepertory" :precision="0" size="mini" style="width: 100px;margin-left: 50px;" controls-position="right" :min="0" />
                </el-form-item>
                <el-form-item label="价格">
                    <el-checkbox v-model="isPrise">批量设置价格</el-checkbox>
                    <el-input-number v-model="prise" size="mini" :disabled="!isPrise" :precision="0" style="width: 100px;margin-left: 50px;" controls-position="right" :min="0" />
                </el-form-item>
                <el-form-item label="会员价格">
                    <el-checkbox v-model="isPriseMember">批量设置会员价格</el-checkbox>
                    <el-input-number v-model="priseMember" size="mini" :disabled="!isPriseMember" :precision="0" style="width: 100px;margin-left: 50px;" controls-position="right" :min="0" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="batchSetPriseRepertoryDialogVisible = false">取 消</el-button>
                <el-button type="primary" :disabled="!isPrise && !isPriseMember && !isRepertory" @click="batchSetPriseRepertorySubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { Alert } from "element-ui";
import oss from "@/util/oss.js"
const toolbarOptions = [
    ["bold", "italic", ], // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
    ["blockquote", "code-block"], // 引用  代码块-----['blockquote', 'code-block']
    [{ header: 1 }, { header: 2 }], // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
    [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
    [{ script: "sub" }, { script: "super" }], // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
    [{ indent: "-1" }, { indent: "+1" }], // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
    [{ direction: "rtl" }], // 文本方向-----[{'direction': 'rtl'}]
    [{ size: ["small", false, "large", "huge"] }], // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
    [{ font: [] }], // 字体种类-----[{ font: [] }]
    [{ align: [] }], // 对齐方式-----[{ align: [] }]
    ["clean"], // 清除文本格式-----['clean']
    ["image"] // 链接、图片、视频-----['link', 'image', 'video']
];

import oucy from "@/util/oucyUtil";
import { furnitureClassify, furnitureGlobalBrand, spu, globalClassify } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"
import addAttr from "@/components/addAttr.vue"
import addSpec from "@/components/addSpec.vue"
// import uploadImage from "@/components/uploadImage.vue"
// import uploadVideo from "@/components/uploadVideo.vue"
import Mywangeditor from "@/components/Mywangeditor"
export default {
    name: "Good",
    components: {
        addAttr,
        addSpec,
        Mywangeditor,
        // uploadImage,
        // uploadVideo,
    },
    data() {
        return {
            categoryId: 0,
            classifyData: [],
            userAffectBrands: [],
            globalAttrs: [],

            dialogImagePreviewUrl: null,
            dialogImagePreviewVisible: false,

            goodForm: {
                classify: null,
                spuName: null,
                spuImgs: [],
                spuBrand: null,
                cityCode: null,
                cityPlaceholder: null,
                attrs: [],
                attrTableData: [],
                attrSpanArr: [],
                spuAttrUserImg: null,
                priseTableData: [],
                points: [],
                globalAttrs: [],
                specTableData: [],
                skuSinglePrise: 0,
                skuSingleRepertory: 100,
                spuRankingWeight: 0,
                skuMemberSinglePrise: 0,
                describer: null,
                globalClassifyId: null,
                spuVideo: null,
                spuIsNew: false,
                spuIsSpecial: false,
                spuIsRecommend: false,
            },
            goodFormRules: {
                classify: [{ required: true, message: '请选择产品分类', trigger: 'blur' }],
                globalClassifyId: [{ required: true, message: '请选择平台分类', trigger: 'blur' }],
                spuName: [{ required: true, message: '请设置产品名称', trigger: 'blur' }],
                spuImgs: [{ required: true, message: '请设置产品主图', trigger: 'blur' }]
            },
            quillEditorOption: {
                placeholder: '填写产品简介',
                theme: "snow",
                modules: {
                    toolbar: toolbarOptions,
                    imageDrop: false, //禁用拖拽上传
                    imageResize: {
                        displayStyles: {
                            backgroundColor: "black",
                            border: "none",
                            color: "white"
                        },
                        modules: ["Resize", "DisplaySize", "Toolbar"]
                    },
                },
            },
            hiddenFileInputId: "hiddenFileInputId",
            spuId: null,
            // 选择规格模板
            addAttrModuleDialogVisible: false,
            // 选择参数模板
            addSpecModuleDialogVisible: false,
            // 分类
            classifyData: [],
            classifyChoose: null,
            pageSize: 10,
            pageNow: 0,
            tempData: [],
            enterpriseId: null,
            // 上传对象
            uploadImageObj: {},
            // 批量设置选择中的
            multipleSelection: [],
            batchSetPriseRepertoryDialogVisible: false,
            prise: 0,
            priseMember: 0,
            repertory: 0,
            allGlobalClassifyList: [],
            globalGroupProps: [],
            videoUrl: null,
            isRepertory: false,
            isPrise: false,
            isPriseMember: false,
            bottomBtnClass:'fixed',//relative
        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        this.enterpriseId = enterprise.id
        const me = this;
        this.$refs.myQuillEditor.quill.getModule("toolbar").addHandler("image", function(image) {
            return new Promise((resolve, reject) => {
                if (image) {
                    let fileInput = document.getElementById(me.hiddenFileInputId);
                    fileInput.click();
                }
                resolve(image);
            });
        });

        if (this.$route.query.spuId) {
            this.spuId = this.$route.query.spuId
            this.querySpu()

        } else {
            me.init();
            let goodForm = localGet('goodForm')
            if (goodForm) {
                this.openDraftsIfon()
            }
        }

        var intersectionObserver = new IntersectionObserver(
           (entries)=> {
            // 如果不可见，就返回
            if (entries[0].intersectionRatio <= 0){
                this.bottomBtnClass='fixed'
            }else{
                this.bottomBtnClass='relative'
            };
          });

        // 开始观察
        intersectionObserver.observe(
          document.querySelector('#scrollerFooter')
        );
    },
    computed:{
        attrTableData:function(){
            let attrTableData=[],attrName=[]
            for (let v of this.goodForm.attrTableData){
                if(v){
                    let index = attrName.indexOf(v.attrName)
                    if(index==-1){
                        attrName.push(v.attrName)
                        attrTableData[attrTableData.length]=[]
                        index=attrTableData.length*1-1
                    }
                    attrTableData[index].push(v)
                }
            }
            return attrTableData
        }
    },
    methods: {
        init: function() {
            const me = this;
            me.goodForm.classify = null;
            me.goodForm.spuBrand = null;
            me.goodForm.globalAttrs = [];
            me.globalAttrs = [];
            furnitureClassify.findByLtUserAuthAndClassifyCategory({ classifyCategory: me.categoryId, enterpriseId: this.enterpriseId }).then(res => {
                // oucy.queryUserClassify(me.categoryId).then(res=>{
                me.classifyData = res;


                return furnitureGlobalBrand.getUserBrands({
                    brandCategory: me.categoryId,
                    brandAuditStatus: oucy.brandAuditStatusEnum.APPLY_SUCCESS,
                    enterpriseId: this.enterpriseId
                });
            }).then(res => {
                me.userAffectBrands = res;

                return oucy.queryGlobalAttr(me.categoryId);
            }).then(res => {
                if(res){
                    for (let v of res) {
                        v.validate = false
                    }
                }
                me.globalAttrs = res;
                me.getAllGlobalClassify()
                // 填回动态属性
                if (me.globalGroupProps) {
                    for (let i = 0; i < me.globalGroupProps.length; i++) {
                        for (let j = 0; j < me.globalAttrs.length; j++) {
                            if (me.globalAttrs[j].id == me.globalGroupProps[i].furnitureGlobalAttrBasic.id) {
                                me.goodForm.globalAttrs[j] = me.globalGroupProps[i].id
                            }
                        }
                    }
                }

            });
        },
        createSkuAttrOptions: function() {
            const me = this;
            return new Promise((resolve, reject) => {
                if (!!me.goodForm.attrTableData) {

                    let skuAttrOptions = [];

                    for (let i = 0; i < me.goodForm.attrSpanArr.length; i++) {

                        let attrName = me.goodForm.attrTableData[i].attrName;
                        let attrOptionName = me.goodForm.attrTableData[i].attrOptionName;
                        let attrOptionImg = me.goodForm.attrTableData[i].attrOptionImg;

                        if (!attrOptionName) {
                            continue;
                        }
                        
                        if ((attrName === me.goodForm.spuAttrUserImg) && (!attrOptionImg)) {
                            reject('你选择了「' + attrName + '」规格带图片，但是「' + attrOptionName + '」规格值却未设置图片');
                        }

                        if (me.goodForm.attrSpanArr[i] === 0) {

                            skuAttrOptions[skuAttrOptions.length - 1].value.push(attrOptionName);

                            if (attrName === me.goodForm.spuAttrUserImg) {
                                skuAttrOptions[skuAttrOptions.length - 1].img.push(attrOptionImg);
                            }

                        } else {

                            if (attrName === me.goodForm.spuAttrUserImg) {
                                skuAttrOptions.push({
                                    key: attrName,
                                    value: [attrOptionName],
                                    img: [attrOptionImg]
                                });
                            } else {
                                skuAttrOptions.push({
                                    key: attrName,
                                    value: [attrOptionName],
                                    img: []
                                });
                            }

                        }
                    }

                    resolve(skuAttrOptions.length > 0 ? JSON.stringify(skuAttrOptions) : null);
                }
            });

        },
        createSkuPriseRepertorys: function() {
            const me = this;
            return new Promise((resolve, reject) => {

                let skuPriseRepertorys = [];

                for (let i = 0; i < me.goodForm.priseTableData.length; i++) {
                    let priseData = me.goodForm.priseTableData[i];

                    let priseDataAttr = priseData.attr;
                    let attrStr = '';
                    for (let j = 0; j < priseDataAttr.length; j++) {
                        if (j !== 0) {
                            attrStr += ";";
                        }
                        attrStr += (priseDataAttr[j].key + ":" + priseDataAttr[j].value);
                    }

                    skuPriseRepertorys.push({
                        attr: attrStr,
                        prise: priseData.prise,
                        priseMember: priseData.priseMember,
                        repertory: priseData.repertory
                    })

                }

                resolve(skuPriseRepertorys.length > 0 ? JSON.stringify(skuPriseRepertorys) : null);
            });
        },
        createSpuImgs: function() {
            const me = this;
            return new Promise((resolve, reject) => {
                let spuImgs = [];
                if (me.goodForm.spuImgs && me.goodForm.spuImgs.length > 0) {
                    me.goodForm.spuImgs.forEach(item => {
                        spuImgs.push(item.url)
                    });
                }
                resolve(spuImgs.length > 0 ? JSON.stringify(spuImgs) : null);
            });
        },
        createSpuGlbAttrOption: function() {
            const me = this;
            return new Promise((resolve, reject) => {
                let spuGlbAttrOption = [];
                if (me.goodForm.globalAttrs && me.goodForm.globalAttrs.length > 0) {
                    me.goodForm.globalAttrs.forEach(item => {
                        if (!!item) {
                            spuGlbAttrOption.push(item);
                        }
                    });
                }
                resolve(spuGlbAttrOption.length > 0 ? JSON.stringify(spuGlbAttrOption) : null);
            });
        },
        createSpecificationOption: function() {
            const me = this;
            return new Promise((resolve, reject) => {
                let specificationOption = [];
                if (me.goodForm.specTableData && me.goodForm.specTableData.length > 0) {
                    me.goodForm.specTableData.forEach(item => {
                        if (!!item && item.affect) {
                            specificationOption.push({
                                key: item.key,
                                value: item.value
                            });
                        }
                    });
                }
                resolve(specificationOption.length > 0 ? JSON.stringify(specificationOption) : null);
            });
        },
        publishGood: function() {
            const me = this;
            let validate = me.validateSpuGlbAttrOption()
            me.$refs["goodForm"].validate((valid) => {
                if (valid) {
                    if (validate) return
                    let postData = {};
                    if (me.spuId) {
                        postData.spuId = me.spuId
                    }
                    me.goodForm.describer=me.$refs.Mywangeditor.getHtml()
                    postData.globalClassifyId = me.goodForm.globalClassifyId[me.goodForm.globalClassifyId.length - 1];
                    postData.spuRankingWeight = me.goodForm.spuRankingWeight;
                    postData.classifyId = me.goodForm.classify;
                    postData.spuName = me.goodForm.spuName;
                    postData.spuIsNew = me.goodForm.spuIsNew ? true : false
                    postData.spuIsSpecial = me.goodForm.spuIsSpecial ? true : false
                    postData.spuIsRecommend = me.goodForm.spuIsRecommend ? true : false
                    // postData.spuIsNew = ["spuIsNew"].subsetTo(me.goodForm.points) ? true : false;
                    // postData.spuIsSpecial = ["spuIsSpecial"].subsetTo(me.goodForm.points) ? true : false;
                    postData.spuDescribe = me.goodForm.describer;
                    postData.brandId = me.goodForm.spuBrand;
                    postData.skuSinglePrise = me.goodForm.skuSinglePrise||0;
                    postData.skuSingleRepertory = me.goodForm.skuSingleRepertory;
                    postData.skuMemberSinglePrise = me.goodForm.skuMemberSinglePrise||0;
                    postData.chinaId = me.goodForm.cityCode == null ? null : me.goodForm.cityCode[me.goodForm.cityCode.length - 1];
                    postData.spuVideo = me.goodForm.spuVideo;
                    me.createSpecificationOption().then(res => {
                        console.log(res);
                        postData.specificationOption = res;
                        return me.createSpuGlbAttrOption();
                    }).then(res => {
                        console.log(res);
                        postData.spuGlbAttrOption = res;
                        return me.createSpuImgs();
                    }).then(res => {
                        console.log(res);
                        if (!res) {
                            return new Promise.reject('产品主图不能为空');
                        }
                        postData.spuImgs = res;
                        return me.createSkuAttrOptions();
                    }).then(res => {
                        console.log(res);
                        postData.skuAttrOptions = res;
                        return me.createSkuPriseRepertorys();
                    }).then(res => {
                        console.log(res);
                        if(res){
                            let list = JSON.parse(res)
                            let hasPrise=null
                            let hasRepertory=null
                            let hasPriseMember=null
                            for(let v of list){
                                if(!v.repertory &&v.repertory!=0){
                                   hasRepertory=v.attr
                                   break
                                }
                                if(!v.prise &&v.prise!=0){
                                   hasPrise=v.attr
                                   break
                                }
                                if(!v.priseMember &&v.priseMember!=0){
                                   hasPriseMember=v.attr
                                   break
                                }
                            }

                            if(hasPrise){
                                me.$alert(hasPrise+'的价格不能为空', '提示信息', {
                                    type: 'warning',
                                    confirmButtonText: '确定'
                                });
                                return
                            }
                            if(hasRepertory){
                                me.$alert(hasRepertory+'的库存不能为空', '提示信息', {
                                    type: 'warning',
                                    confirmButtonText: '确定'
                                });
                                return
                            }
                            if(hasPriseMember){
                                me.$alert(hasPriseMember+'的会员价格不能为空', '提示信息', {
                                    type: 'warning',
                                    confirmButtonText: '确定'
                                });
                                return
                            }
                        }
                        postData.skuPriseRepertorys = res;
                        postData.enterpriseId = this.enterpriseId
                        this.$oucy.keywordFiltr(postData).then(res=>{
                            spu.createSpu(postData).then(res => {
                                me.$notify({
                                    title: me.spuId ? '修改成功' : '发布成功',
                                    message: '后台工作人员会尽快审核您的商品',
                                    type: 'success'
                                });
                                oucy.back()
                        });
                        },err=>{})

                    }).catch(err => {
                        me.$alert(err, '提示信息', {
                            type: 'warning',
                            confirmButtonText: '确定'
                        });
                    });
                }else{
                    me.$alert('请检查必填项', '提示信息', {
                        type: 'warning',
                        confirmButtonText: '确定'
                    });
                }
            });
        },
        handleSpuImgUpload: function(params) {
            const me = this;
            const file = params.file;
            oucy.checkImgFile(file).then(res => {
                return oucy.checkImgSquare(res, 1, 1);
            }).then(res => {
                let Oss=new oss()
                return Oss.startUpload(file)
                // return oucy.uploadRequest(file);
            }).then(res => {
                if (!me.goodForm.spuImgs) {
                    me.goodForm.spuImgs = [];
                }
                me.goodForm.spuImgs.push({
                    url: res
                });
            });
        },
        handleSpuImgChange: function(file, fileList) {
            fileList.pop();
        },
        handleImagePreviewShow(file) {
            this.dialogImagePreviewUrl = file.url;
            this.dialogImagePreviewVisible = true;
        },
        handleImagePreviewDownload(file) {
            console.log(file);
        },
        handleImagePreviewRemove(file) {
            const me = this;
            if (me.goodForm.spuImgs && me.goodForm.spuImgs.length > 0) {
                me.goodForm.spuImgs.forEach(function(item, index, arr) {
                    if (item.url === file.url) {
                        me.goodForm.spuImgs.splice(index, 1);
                    }
                })
            }
        },
        addAttr: function() {
            const me = this;
            me.$prompt('请输入规格名称', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '规格名称不正确'
            }).then(({ value }) => {
                let has = false
                for(let v of me.goodForm.attrs){
                    if(v.key==value){
                        has=true
                        break
                    }
                }
                if(has){
                    this.$message('规格名称"'+value+'"已存在')
                    return
                }
                me.goodForm.attrs.push({
                    key: value,
                    values: []
                });
                me.getSpanArr();
            });
        },
        editAttr: function(v, i) {
            const me = this;
            me.$prompt('修改规格名称', '提示', {
                inputValue: v.attrName,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '规格名称不正确'
            }).then(({ value }) => {
               let has = false
               if(v.attrName==value){
                console.log("相同的没有修改",v.attrName)
                console.log("相同的没有修改",value)
                return
               }
                for(let v of me.goodForm.attrs){
                    if(v.attrName != v.key && v.key==value){
                        has=true
                        break
                    }
                }
                if(has){
                    this.$message('规格名称"'+value+'"已存在')
                    return
                }

                // console.log(v)
                let isValue = false
                for (let vv of me.goodForm.attrs) {
                    if (v.attrName == vv.key) {
                        vv.key = value
                        isValue = vv.values && vv.values.length > 0
                        // break
                    }
                }
                me.getSpanArr();

                isValue && me.createPriseTableData();

            });
        },
        addAttrOption: function(attrName) {
            const me = this;
            me.$prompt('请输入规格值', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '规格值不正确'
            }).then(({ value }) => {
                let has=false
                for (let i = 0; i < me.goodForm.attrs.length; i++) {
                    if (attrName === me.goodForm.attrs[i].key) {
                        for (let v of me.goodForm.attrs[i].values){
                            if(v.key==value){
                                has=true
                                break
                                return
                            }
                        }
                        if(has){
                            this.$message('规格值"'+value+'"已存在')
                            break
                            return
                        }
                        me.goodForm.attrs[i].values.push({
                            key: value,
                            img: null
                        })
                        // break
                    }
                }
                if(has) return
                me.getSpanArr();
                me.createPriseTableData();
            });
        },

        // 修改规格值
        editAttrOption: function(v) {
            const me = this;
            me.$prompt('修改规格值', '提示', {
                inputValue: v.attrOptionName,
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /^\S+$/,
                inputErrorMessage: '规格值不正确'
            }).then(({ value }) => {
                if(v.attrOptionName==value){
                    console.log('规格值没有改变')
                    return
                }
                let has=false
                for (let i = 0; i < me.goodForm.attrs.length; i++) {
                    let attrs = me.goodForm.attrs[i]
                    if (v.attrName === attrs.key) {
                        let values = attrs.values
                        for (let vv of values) {
                            if (value == vv.key) {
                                has=true
                                break
                            }
                        }
                    }
                    if(has)break
                }
                if(has){
                    this.$message('规格值"'+value+'"已存在')
                    return
                }
                for (let i = 0; i < me.goodForm.attrs.length; i++) {
                    let attrs = me.goodForm.attrs[i]
                    if (v.attrName === attrs.key) {
                        let values = attrs.values
                        for (let vv of values) {
                            if (v.attrOptionName == vv.key) {
                                vv.key = value
                                // break
                            }
                        }
                    }
                }
                me.getSpanArr();
                me.createPriseTableData();
            });
        },
        // 规格删除
        deleteAttrOption(v, i) {
            let attrs = this.goodForm.attrs
            for (var i = 0; i < attrs.length; i++) {
                if (attrs[i].key == v.attrName) {
                    attrs.splice(i, 1)
                }
            }
            this.getSpanArr();
            this.createPriseTableData();
        },
        // 规格值删除
        deleteAttrOptionName(v, i, pindex) {
            let attrs = this.goodForm.attrs
            for (let i = 0; i < attrs.length; i++) {
                let values = attrs[i].values
                for (let j = 0; j < values.length; j++) {
                    if (values[j].key == v.attrOptionName) {
                        values.splice(j, 1)
                    }
                }
            }
            this.getSpanArr();
            this.createPriseTableData();
        },
        getSpanArr: function() {
            const me = this;
            let attrTableData = [];
            let attrSpanArr = [];
            for (let i = 0; i < me.goodForm.attrs.length; i++) {
                if (me.goodForm.attrs[i].values.length > 0) {
                    for (let j = 0; j < me.goodForm.attrs[i].values.length; j++) {
                        attrTableData.push({
                            i: i,
                            j: j,
                            rowspan:me.goodForm.attrs[i].values.length,
                            attrName: me.goodForm.attrs[i].key,
                            attrOptionName: me.goodForm.attrs[i].values[j].key,
                            attrOptionImg: me.goodForm.attrs[i].values[j].img
                        });
                    }
                    for (let t = 0; t < me.goodForm.attrs[i].values.length; t++) {
                        if (t === 0) {
                            attrSpanArr.push(me.goodForm.attrs[i].values.length);
                        } else {
                            attrSpanArr.push(0);
                        }
                    }
                   
                    
                } else {
                    attrTableData.push({
                        attrName: me.goodForm.attrs[i].key,
                        attrOptionName: null,
                        attrOptionImg: null
                    });
                    attrSpanArr.push(1);
                }
            }
            me.goodForm.attrTableData = attrTableData;
            me.goodForm.attrSpanArr = attrSpanArr;
            console.log('attrSpanArrattrSpanArrattrSpanArrattrSpanArr',attrSpanArr)
        },
        goodFormSpanMethod: function({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (row.rowspan) {
                    if (row.j == 0) {
                        return {
                            rowspan: row.rowspan,
                            colspan: 1
                        }
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        }
                    }
                }
            }
        },
        handleSpuAttrUserImgChange: function(attrName) {
            const me = this;
            if (!!me.goodForm.spuAttrUserImg && me.goodForm.spuAttrUserImg === attrName) {
                me.goodForm.spuAttrUserImg = null;
            } else {
                me.goodForm.spuAttrUserImg = attrName;
            }

        },
        handleOnBeforeUploadAndSquare: function(file) {
            const me = this;
            return new Promise((resolve, reject) => {
                oucy.checkImgFile(file).then(res => {
                    return oucy.checkImgSquare(res, 1, 1);
                }).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                });
            });

        },
        handleAttrOptionImgUpload: function(params) {
            const me = this;
            const attrName = params.action.split(',')[0];
            const attrOptionName = params.action.split(',')[1];
            const file = params.file;
            oucy.checkImgFile(file).then(res => {
                let Oss=new oss()
                return Oss.startUpload(file)
                // return oucy.uploadRequest(file);
            }).then(res => {
                me.renderAttrImg(attrName, attrOptionName, res);
            });
        },
        renderAttrImg: function(attrName, attrOptionName, imgUrl) {
            const me = this;
            for (let i = 0; i < me.goodForm.attrs.length; i++) {

                if (me.goodForm.attrs[i].key === attrName) {
                    if (me.goodForm.attrs[i].values.length > 0) {

                        for (let j = 0; j < me.goodForm.attrs[i].values.length; j++) {

                            if (me.goodForm.attrs[i].values[j].key === attrOptionName) {
                                me.goodForm.attrs[i].values[j].img = imgUrl;
                                break;
                            }

                        }

                    }
                    break;
                }

            }
            me.getSpanArr();
        },
        createPriseTableData: function() {
            const me = this;

            let priseTableList = [];
            me.goodForm.attrs.forEach((arrItem) => {
                if (priseTableList.length === 0) {
                    let firstItem = [];
                    arrItem.values.forEach(item => {
                        firstItem.push([{
                            key: arrItem.key,
                            value: item.key,
                        }]);
                    });
                    priseTableList = firstItem;
                } else {
                    const emptyArray = [];
                    priseTableList.forEach((priseItem) => {
                        arrItem.values.forEach((item) => {
                            emptyArray.push([...priseItem, {
                                key: arrItem.key,
                                value: item.key
                            }]);
                        });
                    });
                    priseTableList = emptyArray;
                }
            });

            let priseTableData = [];
            for (let i = 0; i < priseTableList.length; i++) {
                priseTableData.push(me.queryCacheData({
                    attr: priseTableList[i],
                    prise: 0,
                    priseMember: 0,
                    repertory: 0
                }));
            }
            me.goodForm.priseTableData = priseTableData;
        },
        queryCacheData: function(newItem) {
            const me = this;
            if (me.goodForm.priseTableData.length > 0) {
                for (let i = 0; i < me.goodForm.priseTableData.length; i++) {
                    let oldItem = me.goodForm.priseTableData[i];
                    if (newItem.attr.subObjectTo(oldItem.attr) || oldItem.attr.subObjectTo(newItem.attr)) {
                        newItem.prise = oldItem.prise;
                        newItem.priseMember = oldItem.priseMember;
                        newItem.repertory = oldItem.repertory;
                        // console.log('找到相同的了++++++++++++++++++')
                        return newItem;
                    }
                }
            }

            return newItem;
        },
        realEditorUploadImg: function() {
            const me = this;
            return new Promise((resolve, reject) => {
                let files = document.getElementById(me.hiddenFileInputId).files;
                if (files.length > 0) {
                    me.mqUpload(files, files.length - 1);
                }
                resolve();
            });
        },
        mqUpload: function(files, index) {
            const me = this;
            let myQuillEditor = me.$refs.myQuillEditor.quill;
            if (files.length > 0 && index < files.length) {
                let Oss=new oss()
                Oss.startUpload(files[index]).then(res => {
                // me.$oucy.uploadRequest(files[index]).then(res => {
                    console.log(me.$refs.myQuillEditor)
                    let rangeIndex = myQuillEditor.getSelection();
                    myQuillEditor.insertEmbed(rangeIndex == null ? 0 : rangeIndex.index, 'image', me.$oucy.ossUrl + res);
                    --index;
                    if (index < 0) {
                        document.getElementById(me.hiddenFileInputId).value = "";
                    } else {
                        me.mqUpload(files, index);
                    }
                }).catch(err => {
                    document.getElementById(me.hiddenFileInputId).value = "";
                })
            }
        },
        addSpecOption: function() {
            const me = this;
            me.goodForm.specTableData.push({
                key: null,
                value: null,
                affect: false
            })
        },
        deleteSpecTableData: function(index) {
            this.goodForm.specTableData.splice(index, 1);
        },
        saveSpecTableData: function(index) {
            const me = this;
            let key = me.goodForm.specTableData[index].key;
            let value = me.goodForm.specTableData[index].value;
            if (!key || !key.Trim() || !value || !value.Trim()) {
                me.$alert('「参数名」和「参数值」不能为空', '提示信息', {
                    type: 'warning',
                    confirmButtonText: '确定'
                });
            } else {
                let has=false
                for (var i = 0; i < me.goodForm.specTableData.length; i++) {
                    if(index!=i){
                        if(me.goodForm.specTableData[i].key == me.goodForm.specTableData[index].key){
                            has=true
                        }
                    }
                }
                if( has){
                    this.$message('参数名 "'+key+'" 已存在')
                    return
                }
                me.goodForm.specTableData[index].affect = true;
            }
        },
        editSpecTableData: function(index) {
            this.goodForm.specTableData[index].affect = false;
        },

        querySpu() {

            oucy.postRequest('/client/product/spu/querySpu', {
                spuId: this.spuId,
            }).then(res => {
                if (res && res.id) {
                    this.categoryId = res.classify && res.classify.classifyCategory
                    this.goodForm.globalClassifyId = res.globalClassifyIds
                    this.globalGroupProps = res.globalGroupProps || []
                    this.init();
                    this.goodForm.id = res.id
                    this.goodForm.spuName = res.spuName
                    // this.categoryId=res.classify.classifyCategory
                    this.goodForm.classify = res.classify.id
                    // this.categoryId = res.brand && res.brand.brandCategory
                    this.goodForm.spuRankingWeight = res.spuRankingWeight
                    for (let v of res.spuImgs) {
                        v.url = v.imgUrl
                    }
                    res.spuImgs = res.spuImgs
                    this.goodForm.spuIsNew = res.spuIsNew
                    this.goodForm.spuIsSpecial = res.spuIsSpecial
                    this.goodForm.spuIsRecommend = res.spuIsRecommend
                    this.goodForm.spuImgs = res.spuImgs
                    this.goodForm.spuVideo = res.spuVideo
                    this.goodForm.spuBrand = res.brand && res.brand.id
                    this.goodForm.cityCode = res.china && res.china.idPath
                    this.goodForm.skuSinglePrise = res.spuMinPrise
                    this.goodForm.skuSingleRepertory = res.quantity
                    this.goodForm.skuMemberSinglePrise = res.spuMinMemberSinglePrise
                    this.goodForm.specTableData = []
                    // 产品规格
                    if (res.groupProps && res.groupProps.length) {
                        for (let v of res.groupProps) {
                            this.goodForm.specTableData.push({
                                key: v.optionName,
                                value: v.optionValue,
                                affect: true,
                            })
                        }
                    }
                    let attrTableData = []
                    if (res.props) {
                        for (let v of res.props) {
                            attrTableData.push({ key: v.attrName, values: [] })
                            for (let vv of v.furnitureAttrOption) {
                                attrTableData[attrTableData.length - 1].values.push({ key: vv.optionValue, img: vv.optionImg })
                                if (vv.optionImg) {
                                    this.goodForm.spuAttrUserImg = v.attrName
                                }
                            }
                        }
                    }
                    this.goodForm.attrs = attrTableData
                    this.getSpanArr();
                    this.createPriseTableData();
                    // 设置价格和库存
                    if (this.goodForm.priseTableData) {
                        for (let v of this.goodForm.priseTableData) {
                            for (let vvv of res.sku) {
                                // 对比
                                if (this.diff(v, vvv)) {
                                    v.prise = vvv.price
                                    v.priseMember = vvv.priseMember
                                    v.repertory = vvv.quantity
                                }
                            }
                        }
                    }

                    this.goodForm.describer = res.spuDescribe
                    this.$refs.Mywangeditor.setHtml(res.spuDescribe)
                }
                // console.log(res)
            });
        },
        diff(v, vvv) {

            let skuName = vvv.skuName.split(";");
            let attr = []
            for (let vv of skuName) {
                let ar = vv.split('--')
                attr.push({
                    key: ar[0],
                    value: ar[1]
                })
            }

            let len = 0
            for (let obj1 of v.attr) {
                for (let obj2 of attr) {
                    if (oucy.isObjectValueEqual(obj1, obj2)) {
                        ++len
                    }
                }
            }

            return v.attr.length == len
        },

        addAttrBack(e) {
            console.log(e)
            this.addAttrModuleDialogVisible = false
            if (e && e.length) {
                let has=false
                let list=[]
                for (let v of e){
                    has = this.hasAttr(v.key)
                    if(!has){
                        list.push(v)
                    }else{
                        this.$message('规格"'+v.key+'"已存在')
                    }

                }
                if(list && list.length){
                    console.log('---------------------------')
                    console.log(list)
                    console.log('---------------------------')
                    this.goodForm.attrs.push(...list)
                    this.getSpanArr();
                    this.createPriseTableData();
                }
            }
        },
        hasAttr(value){
            let has=false
            for(let v of this.goodForm.attrs){
                if(v.key==value){
                    has=true
                    break
                }
            }
            return has
        },
        openAddAttrDialog() {
            this.addAttrModuleDialogVisible = true
            // 定时器异步是为了等待弹框显示
            setTimeout(() => {
                this.$refs.addAttr.init()
            })
        },
        addSpecBack(e) {
            if (e && e.length) {
                let has=false
                let list=[]
                for (let v of e){
                    has = this.hasSpec(v.key)
                    if(!has){
                        list.push(v)
                    }else{
                        this.$message('参数"'+v.key+'"已存在')
                    }

                }
                if(list && list.length){
                    this.goodForm.specTableData.push(...list)
                }
            }
            this.addSpecModuleDialogVisible = false
        },
        hasSpec(value){
            let has=false
            for(let v of this.goodForm.specTableData){
                if(v.key==value){
                    has=true
                    break
                }
            }
            return has
        },
        openAddSpecDialog() {
            this.addSpecModuleDialogVisible = true
            // 定时器异步是为了等待弹框显示
            setTimeout(() => {
                this.$refs.addSpec.init()
            })
        },
        // 上传回调
        uploadImageBack(v) {
            // this.enterApplyForm.avata = v
            console.log(v)
            this.renderAttrImg(this.uploadImageObj.attrName, this.uploadImageObj.attrOptionName, v)
        },
        // 设置上传缓存
        setUploadImageObj(v) {
            this.uploadImageObj = v
            // console.log(v)
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        // 批量修改价格
        batchSetPriseRepertorySubmit() {
            this.batchSetPriseRepertoryDialogVisible = false
            for (let v of this.multipleSelection) {
                if (this.isRepertory) v.repertory = this.repertory
                if (this.isPrise) v.prise = this.prise
                if (this.isPriseMember) v.priseMember = this.priseMember
            }
        },
        // 保存草稿
        SaveDrafts() {

            this.goodForm.describer=this.$refs.Mywangeditor.getHtml()
            console.log('this.goodForm.describer',this.goodForm.describer)
            localSet('goodForm', this.goodForm)
            this.$message('保存成功')
        },
        // 恢复草稿
        RestoreDrafts() {
            this.goodForm = localGet('goodForm')
            this.$refs.Mywangeditor.setHtml(this.goodForm.describer)
        },
        openDraftsIfon() {
            this.$confirm('当前有保存的草稿，是否恢复', '提示', {
                confirmButtonText: '恢复草稿',
                cancelButtonText: '删除草稿',
                type: 'warning'
            }).then(() => {
                this.RestoreDrafts()
                localDel('goodForm')
            }).catch(() => {
                localDel('goodForm')
            });
        },
        uploadImageBack2(v) {
            if (!this.goodForm.spuImgs) {
                this.goodForm.spuImgs = [];
            }
            this.goodForm.spuImgs.push({
                url: v
            });
        },
        // 查询全局分类树
        getAllGlobalClassify() {
            globalClassify.getAllGlobalClassify({ globalClassifyEnum: this.categoryId }).then(res => {

                this.setOPtionsValue(res)
                this.allGlobalClassifyList = res
            })
        },
        // 处理多级选择的数据
        setOPtionsValue(list) {
            if (!list) return list
            for (let v of list) {
                v.value = v.id
                v.label = v.classifyName
                if (v.children && v.children.length) {
                    this.setOPtionsValue(v.children)
                }
            }
        },
        // 验证
        validateSpuGlbAttrOption() {
            let validate = false
            const me = this;
            if(me.globalAttrs){
                for (var i = 0; i < me.globalAttrs.length; i++) {
                    if (!me.globalAttrs[i].attrShouldSelect || me.goodForm.globalAttrs[i]) {
                        me.globalAttrs[i].validate = false
                    } else {
                        if (me.globalAttrs[i].attrShouldSelect && me.globalAttrs[i].furnitureGlobalAttrOption && me.globalAttrs[i].furnitureGlobalAttrOption.length) {
                            me.globalAttrs[i].validate = true
                            validate = true
                        } else {
                            me.globalAttrs[i].validate = !true
                        }
                    }
                }
            }

            return validate
        },
        // 处理全局属性
        createSpuGlbAttrOption: function() {
            const me = this;
            let spuGlbAttrOption = [];
            if (me.goodForm.globalAttrs && me.goodForm.globalAttrs.length > 0) {
                me.goodForm.globalAttrs.forEach(item => {
                    if (!!item) {
                        spuGlbAttrOption.push(item);
                    }
                });
            }
            return spuGlbAttrOption.length > 0 ? JSON.stringify(spuGlbAttrOption) : null;

        },
        uploadVideoBack(v) {
            this.goodForm.spuVideo = v
        },
        move(type, v, i) {
            console.log(v)
            if (v.i == null || v.j == null) return
            if (type == 'up') {
                if (i == 0 || this.goodForm.attrTableData[i].attrName != this.goodForm.attrTableData[i - 1].attrName) {
                    this.$message('已经在顶部了！')
                    return
                }

                this.$oucy.listUpDnMove(this.goodForm.attrs[v.i].values, type, v.j)
                this.getSpanArr();
                this.createPriseTableData();
            } else
            if (type == 'dn') {
                if (i == this.goodForm.attrTableData.length - 1 || this.goodForm.attrTableData[i].attrName != this.goodForm.attrTableData[i + 1].attrName) {
                    this.$message('已经在底部了！')
                    return
                }
                this.$oucy.listUpDnMove(this.goodForm.attrs[v.i].values, type, v.j)
                this.getSpanArr();
                this.createPriseTableData();
            }
        }
    }
}
</script>
<style scoped>
.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.my-form-horizontal {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
}

.avatar {
    width: 50px;
    height: 50px;
    display: block;
}

.avatar-uploader>>>.el-upload {
    border: 1px #d9d9d9 dashed;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    float: left;
}

.avatar-uploader>>>.el-upload:hover {
    border-color: #409EFF;
}

.spuAttrImgCell {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-around;
}

.editor-container {
    /*min-height: 800px;*/
}

.editor {
    line-height: normal !important;
    /*min-height: 700px;*/
}

.editor>>>.ql-toolbar.ql-snow {
    border-radius: 4px 4px 0 0;
}

.editor>>>.ql-container.ql-snow {
    border-radius: 0 0 4px 4px;
}

.selected-brand {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.selected-brand-left {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.ql-container {
    /*min-height: 600px;*/
}


/deep/ .el-card__header {
    background: #F2F4F6;
    font-weight: bold;
}
/deep/ .el-upload--picture-card{
    width: 80px;
    height: 80px;
    line-height: 90px;
}
</style>
<style scoped lang="less">
.bottomBtn {
    z-index: 999;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 60px;
    line-height: 60px;
    background: rgba(255, 255, 255, .7);
    &.fixed{
        position: fixed;        
        box-shadow: 0 0 10px #999;
    }
    &.relative{
        position: relative;       
    }
}
.btn {
    display: inline-block;
    padding: 21px 76px;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #EAEAEA;
    position: relative;
    cursor: pointer;
    vertical-align: middle;
    font-weight: 400;

    .corner {
        position: absolute;
        right: 0;
        bottom: 0;
        display: none;
    }

    &.select {
        border: 1px solid #2090FF;
        color: #2090ff;

        .corner {
            display: inline-block;
        }
    }

    .btnImg {
        vertical-align: inherit;
    }
}

.btn+.btn {
    margin-left: 3px;
}
.cf{
    color: #f00;
}
</style>
<!-- 模板分类 -->
<template>
    <div style="text-align: left;">
        <div class="spec-menu-container">
            <el-select v-model="classifyChoose" @change="queryUserSpecTemplate" placeholder="请选择模板所在的产品分类">
                <el-option v-for="item in classifyData" :key="item.id" :label="item.classifyName" :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div style="width: 100%;height: 400px;overflow: auto;">
            <template v-if="tempData">
                <template v-for="(item,index) in tempData.content">
                    <el-card shadow="never" style="margin-bottom: 25px;">
                        <div slot="header" class="my-header">
                            <div>
                                <el-checkbox v-model="item.checked" @change="changeTemplat(item)">{{item.templateName}}</el-checkbox>
                            </div>
                        </div>
                        <div style="min-height: 100px;" v-if="item.checked">
                            <template v-if="item.specificationOptionDtos">
                                <el-table :data="item.specificationOptionDtos" ref="multipleTable" border style="width: 100%;">
                                    <el-table-column width="55">
                                        <template slot-scope="scope">
                                            <el-checkbox v-model="scope.row.checked"></el-checkbox>
                                        </template>
                                    </el-table-column>
                                    <el-table-column type="index" label="序号" width="50"></el-table-column>
                                    <el-table-column label="参数名" prop="optionName"></el-table-column>
                                    <el-table-column label="参数值" prop="optionValue"></el-table-column>
                                </el-table>
                            </template>
                        </div>
                    </el-card>
                </template>
            </template>
        </div>
        <div>
            <el-divider></el-divider>
            <el-pagination v-if="tempData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="tempData.size" :total="tempData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </div>
        <div class="m-t-30 text-right">
            <el-button type="primary" @click="addSpecBack(true)">确 定</el-button>
            <el-button @click="addSpecBack(!true)">取 消</el-button>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { furnitureClassify, furnitureSpecificationOption } from "@/service"
import { localSet, localGet, localDel } from "@/store/store"

export default {
    name: "Spec",
    data() {
        return {
            classifyData: [],
            classifyChoose: null,
            tempData: [],
            pageSize: 10,
            pageNow: 0,

        }
    },
    mounted() {

    },
    methods: {
        init() {
            const me = this;
            let enterprise = localGet('enterprise')
            this.enterpriseId = enterprise.id
            furnitureClassify.findByLtUserAuthAndClassifyCategory({ enterpriseId: this.enterpriseId }).then(res => {
                // oucy.queryUserClassify(null).then(res=>{
                console.log('11,',res);
                me.classifyData = res;
                return me.queryUserSpecTemplate();
            });
        },


        sizeChange: function(pageSize) {
            const me = this;
            me.pageSize = pageSize;
            me.queryUserSpecTemplate();
        },
        currentChange: function(current) {
            const me = this;
            me.pageNow = current - 1;
            me.queryUserSpecTemplate();
        },
        indexMethod: function(index) {
            const me = this;
            return me.pageSize * me.pageNow + index + 1;
        },
        queryUserSpecTemplate: function() {
            const me = this;
            console.log(me);
            return new Promise((resolve, reject) => {
                if (me.classifyChoose) {
                    furnitureSpecificationOption.getAllEnterpriseInfo({ furnitureClassifyId: me.classifyChoose, start: me.pageNow, limit: me.pageSize, enterpriseId: this.enterpriseId }).then(res => {
                        console.log(res);
                        if(res.totalElements == 0) {
                            res.content = [];
                        }
                        if(res.content.length > 0) {
                            res.content.forEach(v => {
                                v.checked = false
                                if(v.hasOwnProperty('specificationOptionDtos') && v.specificationOptionDtos.length > 0) {
                                    v.specificationOptionDtos.forEach((item) => {
                                        item.checked = false
                                    })
                                }
                            });
                            // for (let v of res.content) {
                            //     console.log(v);
                            //     v.checked = false
                            //     for (let vv of v.specificationOptionDtos) {
                            //         vv.checked = false
                            //     }

                            // }  
                        }
                        me.tempData = res;
                        resolve(res);
                    }).catch(err => {
                        reject(err);
                    })
                } else {
                    resolve();
                }

            })

        },
        // 选择模板
        changeTemplat(v) {
            for (let vv of v.specificationOptionDtos) {
                vv.checked = v.checked
            }
        },
        addSpecBack(t) {
            console.log(t)
            let specTableData = []
            if (t && this.tempData && this.tempData.content) {
                for (let v of this.tempData.content) {
                    if (v.checked) {
                        for (let vv of v.specificationOptionDtos) {
                            if (vv.checked) {
                                specTableData.push({
                                    key: vv.optionName,
                                    value: vv.optionValue,
                                    affect:true
                                })
                            }
                        }
                    }
                }
            }
            this.$emit('addSpecBack', specTableData)
            console.log(specTableData)
        },

    }
}
</script>
<style scoped>
.spec-menu-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}
</style>
<!-- 添加规格模板组件 -->
<template>
    <div style="text-align: left;">
        <div class="attr-menu-container">
            <el-select v-model="classifyChoose" @change="queryUserAttrTemplate" placeholder="请选择模板所在的产品分类">
                <el-option v-for="item in classifyData" :key="item.id" :label="item.classifyName" :value="item.id">
                </el-option>
            </el-select>
            <!-- <el-button @click="addAttrTemp" icon="el-icon-circle-plus" :disabled="!classifyChoose" type="primary">创建新的模板</el-button> -->
        </div>
        <div style="width: 100%;height: 400px;overflow: auto;">
            <template v-if="tempData">
                <template v-for="(item,index) in tempData.content">
                    <el-card shadow="never" style="margin-bottom: 25px;">
                        <div slot="header" class="my-header">
                            <div>
                                <el-checkbox v-model="item.checked" @change="changeTemplateName(item)">{{item.templateName}}</el-checkbox>
                            </div>
                        </div>
                        <div v-if="item.checked">
                            <div v-for="(v,i) of item.furnitureAttr" :key="i">
                                <el-table :data="v.furnitureAttrOption" :span-method="goodFormSpanMethod" border style="width: 100%; margin-top: 20px">
                                    <el-table-column label="规格名称" width="180">
                                        <template slot-scope="scope">
                                            <el-checkbox v-model="v.checked" :disabled="!item.checked" @change="changeAttrName(v)">{{scope.row.attrName}}</el-checkbox>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="optionValue" label="规格值">
                                        <template slot-scope="scope">
                                            <div v-if="scope.row.optionValue" class="optionValue">
                                                <div class="optionValueLeft">
                                                    <el-checkbox :disabled="!v.checked" v-model="scope.row.checked">{{scope.row.optionValue}}</el-checkbox>
                                                </div>
                                            </div>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </el-card>
                </template>
            </template>
        </div>
        <div>
            <el-divider></el-divider>
            <el-pagination v-if="tempData" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="tempData.size" :total="tempData.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
            </el-pagination>
        </div>
        <div class="m-t-30 text-right">
            <el-button type="primary" @click="addAttrBack(true)">确 定</el-button>
            <el-button @click="addAttrBack(!true)">取 消</el-button>
        </div>
    </div>
</template>
<script>
import oucy from "@/util/oucyUtil";
import { furnitureClassify } from '@/service/index.js'
import { localSet, localGet, localDel } from "@/store/store"


export default {
    name: "addAttr",
    data() {
        return {
            classifyData: [],
            classifyChoose: null,
            pageSize: 10,
            pageNow: 0,
            tempData: [],
            enterpriseId: null,
            checked: false,
        }
    },
    mounted() {


        // spec.addFurnitureAttrTemplate()
    },
    methods: {
        init(){
           const me = this;
           let enterprise = localGet('enterprise')
           this.enterpriseId = enterprise.id
           furnitureClassify.findByLtUserAuthAndClassifyCategory({ enterpriseId: this.enterpriseId }).then(res => {
               // oucy.queryUserClassify(null).then(res => {
               console.log(res);
               me.classifyData = res;
               if (me.classifyData && me.classifyData.length) {
                   me.classifyChoose = me.classifyData[0].id
                   me.queryUserAttrTemplate();
               }
           }); 
        },
        sizeChange: function(pageSize) {
            const me = this;
            me.pageSize = pageSize;
            me.queryUserAttrTemplate();
        },
        currentChange: function(current) {
            const me = this;
            me.pageNow = current - 1;
            me.queryUserAttrTemplate();
        },
        indexMethod: function(index) {
            const me = this;
            return me.pageSize * me.pageNow + index + 1;
        },
        queryUserAttrTemplate: function() {
            const me = this;
            return new Promise((resolve, reject) => {
                if (!!me.classifyChoose) {
                    oucy.postRequest('/client/product/furnitureattr/getAllAttrTemplate', {
                        furnitureClassifyId: me.classifyChoose,
                        enterpriseId: this.enterpriseId,
                        start: me.pageNow,
                        limit: me.pageSize
                    }).then(res => {
                        if (res.content) {
                            for (let v of res.content) {
                                if (v.furnitureAttr && v.furnitureAttr.length) {
                                    v.checked = false
                                    for (let vv of v.furnitureAttr) {
                                        vv.checked = false
                                        // 处理规格与规值数据 为合并单元格和展示
                                        let index = 0
                                        if (vv.furnitureAttrOption && vv.furnitureAttrOption.length) {
                                            for (let vvv of vv.furnitureAttrOption) {
                                                vvv.checked = false
                                                vvv.tempId = v.id
                                                vvv.furnitureAttrId = vv.id
                                                vvv.attrName = vv.attrName
                                                vvv.len = vv.furnitureAttrOption.length
                                                vvv.index = index
                                                    ++index
                                            }
                                        } else {
                                            vv.furnitureAttrOption = [{
                                                checked: false,
                                                furnitureAttrId: vv.id,
                                                attrName: vv.attrName,
                                                tempId: v.id,
                                            }]
                                        }
                                    }
                                } else {
                                    v.furnitureAttr = []
                                }
                            }
                        }
                        console.log(res.content)
                        me.tempData = res;
                    });
                } else {
                    resolve();
                }

            })
        },


        goodFormSpanMethod: function({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 0) {
                if (row.len) {
                    if (row.index == 0) {
                        return {
                            rowspan: row.len,
                            colspan: 1
                        }
                    } else {
                        return {
                            rowspan: 0,
                            colspan: 0
                        }
                    }
                }
            }
        },
        // 点击确定
        addAttrBack(t) {
            console.log(t)
            let attrs = []
            if (t && this.tempData && this.tempData.content && this.tempData.content.length) {
                let content = this.tempData.content
                for (let v of content) {
                    if (v.furnitureAttr && v.furnitureAttr.length) {
                        // 选中的模板
                        if (v.checked == true) {
                            for (let vv of v.furnitureAttr) {
                                // 选中的规格
                                if (vv.checked == true) {
                                    attrs.push({
                                        key: vv.attrName,
                                        values: []
                                    });
                                    if (vv.furnitureAttrOption && vv.furnitureAttrOption.length) {
                                        for (let vvv of vv.furnitureAttrOption) {
                                            // 选中的规格值
                                            if (vvv.checked == true && vvv.optionValue) {
                                                attrs[attrs.length - 1].values.push({
                                                    key: vvv.optionValue,
                                                    img: null
                                                })
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.$emit('addAttrBack', attrs)
        },
        // 选择模板
        changeTemplateName(v) {
            for (let vv of v.furnitureAttr) {
                vv.checked = v.checked
                for (let vvv of vv.furnitureAttrOption) {
                    vvv.checked = v.checked
                }
            }
        },
        // 选择规格
        changeAttrName(v) {
            for (let vvv of v.furnitureAttrOption) {
                vvv.checked = v.checked
            }
        }
    }
}
</script>
<style scoped>
.attr-menu-container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
}

.my-header {
    display: flex;
    display: -webkit-flex;
    flex-wrap: nowrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
}

.top {
    background: #F2F4F6;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.top .left {
    color: #666;
}

.top .right {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.optionValue {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
</style>
<template>
  <div class="df goods-Richtext">
    <div id="Mywangeditor" style="flex: 1"></div>
    <div class="htmls" style="margin-left: 15px">
      <img src="@/assets/images/phone_top.png" alt="" style="width: 260px" />
      <div v-html="html" class="html"></div>
    </div>
  </div>
</template>
<script>
import oucy from '@/util/oucyUtil'
import E from 'wangeditor'
import oss from '@/util/oss.js'
import { localSet, localGet, localDel } from '@/store/store'
export default {
  name: 'Mywangeditor',
  props: {
    // data: {
    //     type: Object,
    //     default: {},
    //     editor: null,
    // },
  },
  data() {
    return {
      html: '',
    }
  },
  mounted() {
    this.editor = new E('#Mywangeditor')

    // 默认情况下，显示所有菜单
    this.editor.config.menus = [
      'head',
      'bold',
      'fontSize',
      'fontName',
      'italic',
      'underline',
      'strikeThrough',
      'indent',
      'lineHeight',
      'foreColor',
      'backColor',
      // 'link',
      'list',
      // 'todo',
      'justify',
      'quote',
      'emoticon',
      'image',
      // 'video',
      // 'table',
      // 'code',
      'splitLine',
      'undo',
      'redo',
    ]
    this.editor.config.pasteIgnoreImg = true
    this.editor.config.showLinkImg = false
    // 配置触发 onchange 的时间频率，默认为 200ms
    this.editor.config.onchangeTimeout = 500 // 修改为 500ms
    // 配置 onchange 回调函数
    this.editor.config.onchange = (newHtml) => {
      this.html = newHtml
    }

    this.editor.config.customUploadImg = function (files, insertImgFn) {
      function mqUpload(files, index) {
          console.log(files, index)
        let Oss = new oss()
        files && files.length && Oss.startUpload(files[index])
          // oucy.uploadRequest(files[index])
          .then((res) => {
            ++index
            console.log('uploadRequest', index)
            insertImgFn(oucy.ossUrl + res)
            if (index < files.length) {
              mqUpload(files, index)
            }
          })
          .catch((err) => {})
      }
      if (files.length > 0) {
        mqUpload(files, 0)
      }
    }
    this.editor.create()
  },
  methods: {
    setHtml(html) {
      this.editor.txt.html(html)
    },
    getHtml() {
      return this.editor.txt.html()
    },
    getText() {
      return this.editor.txt.text()
    },
    clear() {
      this.editor.txt.clear()
    },
  },
  beforeDestroy() {
    // 销毁编辑器
    this.editor.destroy()
    this.editor = null
  },
}
</script>
<style scoped lang="less">
#Mywangeditor {
  width: auto;
  height: 427px;
  img {
    max-width: 100%;
  }
}
.htmls {
  position: relative;
  border: 1px solid #eaeaea;
}
.html {
  position: absolute;
  top: 60px;
  left: 10px;
  // background:#030;
  width: 241px;
  max-height: 354px;
  overflow: auto;
  flex-wrap: wrap;
  img {
    max-width: 100%;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
